import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router';
import dayjs from 'dayjs';
import { useData } from '../../DataProvider';
import { getAllowedCustomers, getAllowedPayers, getUserRole, getCustomerId, getUserEmail } from '../utils/authHelper';

import { makeStyles, Container, Typography, Switch, FormControlLabel } from '@material-ui/core';

import gql from 'graphql-tag';
import { Query, ApolloConsumer, withApollo } from 'react-apollo';
import fragments from '../utils/graphQL/fragments';
import * as Sentry from '@sentry/react';

import { useTools } from '../hooks/useTools';

import Loading from '../utils/Loading';
import MovesFilter from './moves/MovesFilter';
import MoveCancelModal from './moves/MoveCancelModal';

import { AccordianTable, AccordianRow, TableSort } from '../reusable/AccordianTable';

import { ExportToCsv } from 'export-to-csv';

const log = false;

const capFirst = str => {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1);
};

/** Capitalize the first letter of each word */
const capEach = (str = null) => {
  if (str && typeof str === `string`) {
    if (!str.includes(` `)) return str.charAt(0).toUpperCase() + str.slice(1);
    else {
      let arr = str.split(` `);
      arr = arr.map(s => s.charAt(0).toUpperCase() + s.slice(1));
      return arr.join(` `);
    }
  }
  return str;
};

const getDefaultDisable = () => {
  const localRange = localStorage.getItem(`move-index-range`);
  if (localRange && localRange === `custom`) return false;
  else return true;
};
const getDefaultRange = () => {
  const localRange = localStorage.getItem(`move-index-range`);
  if (localRange) return localRange;
  else return `week`;
};
const getDefaultStart = () => {
  const localRange = localStorage.getItem(`move-index-range`);
  const localStart = localStorage.getItem(`move-index-start`);
  if (localRange && localRange !== `custom`) return dayjs.utc(dayjs().startOf(`day`).subtract(1, localRange)).format();
  else if (localRange === `custom` && localStart) return localStart;
  else return dayjs.utc(dayjs().startOf(`day`).subtract(1, `week`)).format();
};
const getDefaultEnd = () => {
  const localRange = localStorage.getItem(`move-index-range`);
  const localEnd = localStorage.getItem(`move-index-end`);
  if (localRange && localRange !== `custom`) return dayjs.utc(dayjs().endOf(`day`)).format();
  else if (localRange === `custom` && localEnd) return localEnd;
  else return dayjs.utc(dayjs().endOf(`day`)).format();
};

const defaultOrder = `desc`;
const defaultOrderBy = `MOVE_ID`;

////////// COMPONENT //////////
function Moves(props) {
  const ctx = useData();
  const cls = useStyles();

  const { getWorkflowData } = useTools();

  const [disablePickers, setDisablePickers] = useState(false);
  const [range, setRange] = useState(`week`);
  const [start, setStart] = useState(dayjs.utc(dayjs().startOf(`day`).subtract(1, `week`)).format());
  const [end, setEnd] = useState(dayjs.utc(dayjs().endOf(`day`)).format());

  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [expandedRowId, setExpandedRowId] = useState(0);

  const [modal, setModal] = useState({ open: false });

  const [allCustomersChecked, setAllCustomersChecked] = useState(false);
  const [customersArray, setCustomersArray] = useState([]);
  const [payersArray, setPayersArray] = useState([]);
  const [isAdmin, setIsAdmin] = useState(null);
  const [selectedUsersArray, setSelectedUsersArray] = useState([]);

  // let customerId = parseInt(ctx.customerOverride || ctx.customerId ? ctx.customerId : 0);

  const handleRefetch = async () => {
    try {
      await ctx.apolloClient.query({
        query: !isAdmin || allCustomersChecked === true ? GET_ALLOWED_CUSTOMERS_MOVES : GET_SELECTED_CUSTOMERS_MOVES,
        variables:
          !isAdmin || allCustomersChecked === true
            ? { start: start, end: end, customersArray: customersArray }
            : {
                start: start,
                end: end,
                customerId: ctx.customerOverride || ctx.customerId,
                customersArray: customersArray,
              },
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    let defaultDisable = getDefaultDisable();
    setDisablePickers(defaultDisable);
    let defaultRange = getDefaultRange();
    setRange(defaultRange);
    let defaultStart = getDefaultStart();
    setStart(defaultStart);
    let defaultEnd = getDefaultEnd();
    setEnd(defaultEnd);

    const buildAllowedCustomersArray = async () => {
      let customers = await getAllowedCustomers();
      let customer = await getCustomerId();
      customer = parseInt(customer);
      const payers = await getAllowedPayers();
      if (payers && payers.length > 0 && customers && customers.length > 0) {
        customers = customers.concat(payers, customer);
        setPayersArray(payers);
      }
      setCustomersArray(customers);
    };

    const checkIfAdmin = async () => {
      const role = await getUserRole();
      if (role && role.includes('admin')) {
        setIsAdmin(true);
      } else setIsAdmin(false);
    };

    buildAllowedCustomersArray();
    checkIfAdmin();
  }, []);

  React.useEffect(() => {
    handleRefetch();
  }, [ctx.customerOverride, ctx.customerId, customersArray, ctx.apolloClient]);

  const goToMoveDetails = moveId => {
    props.history.push(`/moves/${moveId}`);
  };

  // Control range picker
  const handleRangeChange = value => {
    log && console.log(`handleRangeChange() Value:`, value);
    localStorage.setItem(`move-index-range`, value);
    if (value !== `custom`) {
      setDisablePickers(true);
      setRange(value);
      setStart(dayjs.utc(dayjs().startOf(`day`).subtract(1, value)).format());
      setEnd(dayjs.utc(dayjs().endOf(`day`)).format());
    } else {
      setDisablePickers(false);
      setRange(value);
      setStart(getDefaultStart());
      setEnd(getDefaultEnd());
    }
  };

  // Control date pickers
  const handleDateChange = (value, name) => {
    log && console.log(`Date Change:`, { value, name });
    if (name === `start`) {
      const newDate = dayjs.utc(dayjs(value).startOf(`day`)).format();
      localStorage.setItem(`move-index-start`, newDate);
      setStart(newDate);
    } else {
      const newDate = dayjs.utc(dayjs(value).endOf(`day`)).format();
      localStorage.setItem(`move-index-end`, newDate);
      setEnd(newDate);
    }
  };

  const applyFilters = data => {
    const filterBySearch = () => {
      if (!search || search.length < 1) {
        let filteredData = data.filter(move => move.id);
        return filteredData;
      } else {
        return data.filter(move => {
          const status = getFormattedCombinedStatusFromMove(move);
          const moveCreator = move.createdBy
            ? move.createdBy
            : move.eventlogs && move.eventlogs.length > 0 && move.eventlogs[0] && move.eventlogs[0].user
            ? move.eventlogs[0].user
            : null;
          if (
            (move.id && (move.id + ``).toLocaleLowerCase().includes(search)) ||
            (move.reference_num && move.reference_num.toLocaleLowerCase().includes(search)) ||
            (move.vehicle_stock && move.vehicle_stock.toLocaleLowerCase().includes(search)) ||
            (move.lane && move.lane.description && move.lane.description.toLocaleLowerCase().includes(search)) ||
            (status && status.toLocaleLowerCase().includes(search)) ||
            (moveCreator && moveCreator.toLocaleLowerCase().includes(search))
          ) {
            return true;
          } else return false;
        });
      }
    };

    const filteredBySearch = filterBySearch();
    const filteredByUsers = filteredBySearch.filter(move => {
      if (!selectedUsersArray || selectedUsersArray.length === 0) {
        return true;
      }
      const moveCreator = move.createdBy
        ? move.createdBy
        : move.eventlogs && move.eventlogs.length > 0 && move.eventlogs[0] && move.eventlogs[0].user
        ? move.eventlogs[0].user
        : null;
      return selectedUsersArray.includes(moveCreator);
    });
    return filteredByUsers;
  };

  /** Get formatted combined status string from a drive or ride */
  const getFormattedCombinedStatusFromMove = (move = null) => {
    // Default fallback status string when not enough info is provided
    let fallbackStatus = `Unknown`;

    // Check for move
    if (move) {
      // Set local variables
      fallbackStatus = move.move_type === `drive` ? `Not Assigned` : `Not Started`;
      const cancelStatus = move && move.cancel_status ? move.cancel_status.toLowerCase() : null;
      const status = move && move.status ? move.status.toLowerCase() : null;

      // Check statuses and return a formatted string
      if (cancelStatus) {
        if (cancelStatus === `pending`) return `Pending Cancel`;
        if (cancelStatus === `seen`) return `Cancel Was Seen`;
        if (cancelStatus === `canceled`) return `Cancel Before Started`;
        if (cancelStatus === `started`) return `Cancel After Started`;
        if (cancelStatus === `delivered`) return `Cancel After Delivered`;
        return capEach(cancelStatus);
      }
      if (status) {
        if (status === `dispatched`) return `Dispatched`;
        if (status === `pickup started` || status === `pickup_started`) return `Pickup Started`;
        if (status === `pickup arrived` || status === `pickup_arrived`) return `Pickup Arrived`;
        if (status === `pickup successful` || status === `pickup_successful`) return `Pickup Successful`;
        if (status === `delivery started` || status === `delivery_started`) return `Delivery Started`;
        if (status === `delivery arrived` || status === `delivery_arrived`) return `Delivery Arrived`;
        if (status === `delivery successful` || status === `delivery_successful`) return `Delivery Successful`;
        if (status === `awaitingresponse` || status === `awaiting response` || status === `awaiting_response`)
          return `Awaiting Response`;
        if (status === `accepted`) return `Accepted`;
        if (status === `arrived`) return `Arrived`;
        if (status === `pickedup` || status === `picked up` || status === `picked_up`) return `Picked Up`;
        if (status === `droppedoff` || status === `dropped off` || status === `dropped_off`) return `Dropped Off`;
        if (status === `canceled`) return `Canceled`;
        if (status === `failed`) return `Failed`;
        return capEach(status);
      }
    }
    return fallbackStatus;
  };

  /** Get formatted vehicle string from the move object */
  const getFormattedVehicleFromMove = (move = null, includeYear = true, includeColor = true) => {
    // Default fallback vehicle string when not enough info is provided
    const fallbackVehicle = `Vehicle`;

    // Check for move
    if (move) {
      // Set local variables
      const year = move.vehicle_year || move.driver_vehicle_year || null;
      const make = move.vehicle_make || move.driver_vehicle_make || null;
      const model = move.vehicle_model || move.driver_vehicle_model || null;
      const color = move.vehicle_color || move.driver_vehicle_color || null;

      // Set local vehicle string
      let vehicle = ``;

      // Check for make and model
      if (make && model) vehicle = `${make} ${model}`;
      else if (make) vehicle = make;
      else if (model) vehicle = model;
      else vehicle = fallbackVehicle;

      // Include color and year if specified
      if (includeYear && year) vehicle = `${year} ${vehicle}`;
      if (includeColor && color) vehicle = `${vehicle} (${capFirst(color)})`;

      // Return the built vehicle string
      return vehicle;
    }
    return fallbackVehicle;
  };

  const formatTime = time => {
    if (time && typeof time === `string`) {
      let newTime = dayjs(time).format(`MM/DD/YYYY hh:mm A z`);
      newTime = newTime.replace(/\s/g, '\xa0');
      return newTime;
    }
    return null;
  };

  /** Generate a CSV list of the current moves index table data */
  const generateCSV = (moves = [], start = null, end = null) => {
    if (moves && moves.length > 0) {
      try {
        // Gather extra pickup headers to show for the csv rows
        const gatherExtraPickupHeaders = moves => {
          let extraPickupHeaders = [];
          moves.forEach(move => {
            const pickupWorkflowData = getWorkflowData(
              `pickup`,
              move.pickup_workflow_data,
              `csv`,
              false,
              move.workflowset
            );
            if (pickupWorkflowData && Object.keys(pickupWorkflowData).length > 0) {
              const workflowKeys = Object.keys(pickupWorkflowData);
              extraPickupHeaders = [...extraPickupHeaders, ...workflowKeys];
            }
          });
          return [...new Set(extraPickupHeaders)];
        };
        const extraPickupHeaders = gatherExtraPickupHeaders(moves);

        // Gather extra delivery headers to show for the csv rows
        const gatherExtraDeliveryHeaders = moves => {
          let extraDeliveryHeaders = [];
          moves.forEach(move => {
            const deliveryWorkflowData = getWorkflowData(
              `delivery`,
              move.delivery_workflow_data,
              `csv`,
              false,
              move.workflowset
            );
            if (deliveryWorkflowData && Object.keys(deliveryWorkflowData).length > 0) {
              const workflowKeys = Object.keys(deliveryWorkflowData);
              extraDeliveryHeaders = [...extraDeliveryHeaders, ...workflowKeys];
            }
          });
          return [...new Set(extraDeliveryHeaders)];
        };
        const extraDeliveryHeaders = gatherExtraDeliveryHeaders(moves);

        // Create rows and options for CSV
        const createCsvRow = move => {
          const pickupWorkflowData =
            getWorkflowData(`pickup`, move.pickup_workflow_data, `csv`, false, move.workflowset) || {};
          const extraPickupData = {};
          extraPickupHeaders.forEach(key => {
            extraPickupData[key] = pickupWorkflowData[key] !== undefined ? pickupWorkflowData[key] : ``;
          });

          const deliveryWorkflowData =
            getWorkflowData(`delivery`, move.delivery_workflow_data, `csv`, false, move.workflowset) || {};
          const extraDeliveryData = {};
          extraDeliveryHeaders.forEach(key => {
            extraDeliveryData[key] = deliveryWorkflowData[key] !== undefined ? deliveryWorkflowData[key] : ``;
          });

          return {
            move_id: move.id,
            status: getFormattedCombinedStatusFromMove(move) || ``,
            customer_name: move.customer.name || ``,
            pickup_time: formatTime(move.pickup_time) || ``,
            reference_num: move.reference_num || ``,
            vehicle_stock: move.vehicle_stock || ``,
            vehicle_vin: move.vehicle_vin || `No Vin Provided`,
            vehicle_make: move.vehicle_make || ``,
            vehicle_model: move.vehicle_model || ``,
            vehicle_year: move.vehicle_year || ``,
            vehicle_color: move.vehicle_color || ``,
            vehicle_odometer: move.vehicle_odometer || ``,
            formatted_vehicle: getFormattedVehicleFromMove(move) || ``,
            lane: move.lane.description || ``,
            ...extraPickupData,
            ...extraDeliveryData,
          };
        };
        const csvRows = moves.map(move => createCsvRow(move));

        // Set CSV options
        const csvOptions = {
          filename: `moves_${dayjs(start).format(`MM-DD-YYYY`)}_to_${dayjs(end).format(`MM-DD-YYYY`)}`,
          useKeysAsHeaders: true,
        };

        // Create and generate the CSV
        const csvExporter = new ExportToCsv(csvOptions);
        csvExporter.generateCsv(csvRows);
        toast.success(`Generated CSV!`, { autoClose: 2000 });
      } catch (err) {
        toast.error(`Failed to generate CSV!`);
        console.error(`Failed to generate CSV:`, err);
      }
    } else {
      toast.error(`Failed to generate CSV - No moves found!`);
      console.error(`Failed to generate CSV - No moves found!`);
    }
  };

  const handleModalOpen = (input = null) => {
    setModal({ ...modal, open: true, input: input });
  };
  const handleModalClose = () => {
    setModal({ ...modal, open: false });
  };

  const getTableActions = moves => {
    return [
      { name: `generate-csv`, label: `Generate\xa0CSV`, data: { moves: moves }, handler: handleContextMenuClick },
    ];
  };
  const getRowActions = move => {
    return [
      { name: `move-details`, label: `Move\xa0Details`, data: { move: move }, handler: handleContextMenuClick },
      {
        name: `cancel-move`,
        label: `Cancel\xa0Move`,
        data: { move: move },
        handler: handleContextMenuClick,
        hide: move.cancel_status || move.status === `delivery successful`,
      },
    ];
  };

  const handleContextMenuClick = async (e, data, target) => {
    if (data.action.name === `generate-csv`) {
      generateCSV(data.moves, start, end);
      return;
    }
    if (data.action.name === `move-details`) {
      goToMoveDetails(data.move.id);
      return;
    }
    if (data.action.name === `cancel-move`) {
      handleModalOpen(data.move);
      return;
    }
  };

  const handleSwitch = event => {
    setAllCustomersChecked(event.target.checked);
  };

  //Only show "rooftop" column in table if user is type admin/dealer-admin
  const handleTableHeaders = () => {
    var headers = [];
    if (isAdmin) {
      headers = [
        { id: `MOVE_ID`, alignLeft: true, numeric: true, label: `Move\xa0ID` },
        { id: `ROOFTOP`, alignLeft: true, numeric: true, label: `Rooftop` },
        { id: `REF_NUM`, alignLeft: true, numeric: true, label: `Ref\xa0#` },
        { id: `MOVE_TIME`, alignLeft: true, numeric: true, label: `Move\xa0Time` },
        { id: `STOCK`, alignLeft: false, numeric: true, label: `Stock` },
        { id: `LANE`, alignLeft: true, numeric: false, label: `Lane` },
        { id: `STATUS`, alignLeft: false, numeric: false, label: `Status` },
      ];
    } else {
      headers = [
        { id: `MOVE_ID`, alignLeft: true, numeric: true, label: `Move\xa0ID` },
        { id: `REF_NUM`, alignLeft: true, numeric: true, label: `Ref\xa0#` },
        { id: `MOVE_TIME`, alignLeft: true, numeric: true, label: `Move\xa0Time` },
        { id: `STOCK`, alignLeft: false, numeric: true, label: `Stock` },
        { id: `LANE`, alignLeft: true, numeric: false, label: `Lane` },
        { id: `STATUS`, alignLeft: false, numeric: false, label: `Status` },
      ];
    }
    //Add payers row if customer has allowed third party payers
    if (payersArray && payersArray.length > 0) {
      headers.splice(-1, 0, { id: `PAYER`, alignLeft: true, numeric: false, label: `Payer` });
    }
    return headers;
  };

  //Only show "rooftop" column in table if user is type admin/dealer-admin
  const handleTableRows = move => {
    if (isAdmin) {
      return {
        MOVE_ID: move.id,
        ROOFTOP: move.customer && move.customer.name ? move.customer.name : `-`,
        REF_NUM: move.reference_num ? move.reference_num : `-`,
        MOVE_TIME: formatTime(move.pickup_started ? move.pickup_started : move.pickup_time),
        STOCK: move.vehicle_stock ? move.vehicle_stock.toUpperCase() : `-`,
        LANE: move.lane && move.lane.description ? move.lane.description : `Unknown Lane`,
        PAYER: move.payer_id ? move.payer_id : move.customer.name,
        STATUS: getFormattedCombinedStatusFromMove(move),
        move: move,
      };
    } else {
      return {
        MOVE_ID: move.id,
        REF_NUM: move.reference_num ? move.reference_num : `-`,
        MOVE_TIME: formatTime(move.pickup_started ? move.pickup_started : move.pickup_time),
        STOCK: move.vehicle_stock ? move.vehicle_stock.toUpperCase() : `-`,
        LANE: move.lane && move.lane.description ? move.lane.description : `Unknown Lane`,
        PAYER: move.payer ? move.payer.name : move.customer.name,
        STATUS: getFormattedCombinedStatusFromMove(move),
        move: move,
      };
    }
  };

  //Only show "rooftop" column in table if user is type admin/dealer-admin
  const handleTableColumns = row => {
    var columns = [];
    if (isAdmin) {
      columns = [
        { align: 'left', value: row.MOVE_ID },
        { align: 'left', value: row.ROOFTOP },
        { align: 'left', value: row.REF_NUM },
        { align: 'left', value: row.MOVE_TIME },
        { align: 'right', value: row.STOCK },
        { align: 'left', value: row.LANE },
        { align: 'right', value: row.STATUS },
      ];
    } else {
      columns = [
        { align: 'left', value: row.MOVE_ID },
        { align: 'left', value: row.REF_NUM },
        { align: 'left', value: row.MOVE_TIME },
        { align: 'right', value: row.STOCK },
        { align: 'left', value: row.LANE },
        { align: 'right', value: row.STATUS },
      ];
    }
    //Add payers row if customer has allowed third party payers
    if (payersArray && payersArray.length > 0) {
      columns.splice(-1, 0, { align: 'left', value: row.PAYER });
    }
    return columns;
  };

  return (
    <>
      <MoveCancelModal open={modal.open} onClose={handleModalClose} moveInput={modal.input} />

      <div className={cls.root}>
        <Container maxWidth='lg'>
          {/* Change to flexbox */}
          <div className={cls.header}>
            <Typography className={cls.titleText}>Move Index</Typography>
            {isAdmin ? (
              <FormControlLabel
                className={cls.allCustomerSwitch}
                control={
                  <Switch
                    checked={allCustomersChecked}
                    onChange={handleSwitch}
                    inputProps={{ 'aria-label': 'all customers checkbox' }}
                  />
                }
                label='Show All Rooftops'
              />
            ) : null}
          </div>

          <MovesFilter
            refetch={handleRefetch}
            range={range}
            start={start}
            end={end}
            onRangeChange={handleRangeChange}
            onDateChange={handleDateChange}
            disablePickers={disablePickers}
          />

          {/* <Divide spacer tip="See all moves within the specified time period.">Table View</Divide> */}
          {ctx && ctx.firebaseUser && customersArray && (
            <ApolloConsumer>
              {client => (
                <Query
                  query={
                    !isAdmin || allCustomersChecked === true
                      ? GET_ALLOWED_CUSTOMERS_MOVES
                      : GET_SELECTED_CUSTOMERS_MOVES
                  }
                  variables={
                    !isAdmin || allCustomersChecked === true
                      ? { start: start, end: end, customersArray: customersArray }
                      : {
                          start: start,
                          end: end,
                          customerId: ctx.customerOverride || ctx.customerId,
                          customersArray: customersArray,
                        }
                  }
                  onError={error => {
                    console.error(error);
                    Sentry.captureException(error);
                  }}
                  fetchPolicy='network-only'
                >
                  {({ loading, data }) => {
                    if (loading) {
                      return <Loading fixed />;
                    }
                    if (data && data.moves && data.moves.length > 0) {
                      log && console.log(`Moves for Move Index:`, data.moves);
                      const filteredData = applyFilters(data.moves);
                      const headers = handleTableHeaders();
                      const rows = filteredData.map(move => handleTableRows(move));

                      let userSet = new Set();
                      userSet.add(getUserEmail());
                      data.moves.forEach(move => {
                        if (move.eventlogs && move.eventlogs.length > 0) {
                          userSet.add(move.eventlogs[0].user);
                        }
                      });

                      let userNames = Array.from(userSet).sort();

                      return (
                        <>
                          <AccordianTable
                            title={`${rows.length} Moves`}
                            headers={headers}
                            rows={rows}
                            actions={getTableActions(data.moves)}
                            search={search}
                            defaultOrder={defaultOrder}
                            defaultOrderBy={defaultOrderBy}
                            order={order}
                            orderBy={orderBy}
                            tablePage={tablePage}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            setSearch={setSearch}
                            setOrder={setOrder}
                            setOrderBy={setOrderBy}
                            setTablePage={setTablePage}
                            setRowsPerPage={setRowsPerPage}
                            setExpandedRowId={setExpandedRowId}
                            className={cls.table}
                            refetch={handleRefetch}
                            refreshPersistAs={'moves'}
                            type={'moves'}
                            setSelectedUsersArray={setSelectedUsersArray}
                            selectedUsersArray={selectedUsersArray}
                            userOptions={userNames}
                          >
                            {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
                              .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                              .map(row => (
                                <AccordianRow
                                  key={`index-move-${row.MOVE_ID}`}
                                  rowId={row.MOVE_ID}
                                  expandedRowId={expandedRowId}
                                  setExpandedRowId={setExpandedRowId}
                                  columns={handleTableColumns(row)}
                                  actions={getRowActions(row.move)}
                                  onClick={() => goToMoveDetails(row.move.id)}
                                  className={cls.row}
                                >
                                  <div></div>
                                </AccordianRow>
                              ))}
                          </AccordianTable>
                        </>
                      );
                    } else
                      return (
                        <div className={cls.notFound}>
                          <Typography className={cls.notFoundTxt}>NO MOVES FOUND</Typography>
                        </div>
                      );
                  }}
                </Query>
              )}
            </ApolloConsumer>
          )}
        </Container>
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  row: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      background: '#eee',
    },
    transition: '0.1s',
    cursor: 'pointer',
    textOverflow: 'ellipsis',
  },
  notFound: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    background: '#fff',
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '21px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    // alignContent: 'flex-start',
    marginBottom: theme.spacing(3),
  },
  titleText: {
    marginRight: theme.spacing(2),
    display: 'inline-block',
    // lineHeight: 1,
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  allCustomerSwitch: {
    // lineHeight: 1,
    fontSize: '24px',
    fontWeight: 500,
  },
}));

////////// GRAPHQL //////////
const GET_SELECTED_CUSTOMERS_MOVES = gql`
  query get_selected_customers_moves(
    $start: timestamptz!
    $end: timestamptz!
    $customerId: bigint!
    $customersArray: [bigint]
  ) {
    moves(
      where: {
        active: { _eq: 1 }
        move_type: { _ilike: "drive" }
        customer_id: { _eq: $customerId }
        _and: [
          { _or: [{ payer_id: { _is_null: true } }, { payer_id: { _in: $customersArray } }] }
          {
            _or: [
              { pickup_time: { _gte: $start, _lte: $end } }
              { _and: [{ ready_by: { _gte: $start, _lte: $end } }, { pickup_time: { _is_null: true } }] }
            ]
          }
        ]
      }
      order_by: { id: desc }
    ) {
      ...Move
      eventlogs {
        user
      }
    }
  }
  ${fragments.move}
`;

const GET_ALLOWED_CUSTOMERS_MOVES = gql`
  query get_allowed_customers_moves($start: timestamptz!, $end: timestamptz!, $customersArray: [bigint!]) {
    moves(
      where: {
        active: { _eq: 1 }
        move_type: { _ilike: "drive" }
        _and: [
          { _or: [{ payer_id: { _is_null: true } }, { payer_id: { _in: $customersArray } }] }
          {
            _or: [
              { pickup_time: { _gte: $start, _lte: $end } }
              { _and: [{ ready_by: { _gte: $start, _lte: $end } }, { pickup_time: { _is_null: true } }] }
            ]
          }
        ]
      }
      order_by: { id: desc }
    ) {
      ...Move
      eventlogs {
        user
      }
    }
  }
  ${fragments.move}
`;

////////// EXPORT //////////

const MovesWithRouter = withRouter(Moves);
const MovesWithRouterAndApollo = withApollo(MovesWithRouter);

export default MovesWithRouterAndApollo;
